import './App.css';
import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import ActivityTable from './ActivityTable';

function App(props) {
  // const { onAuthCheckState } = props;
  // useEffect(() => {
  //   onAuthCheckState();
  // }, [onAuthCheckState]);

  const emptyActivity = [{ "username": "n/a", "method": "Loading Data", "from": "127.0.0.1", "description": "n/a", "when": moment().format() }];
  const [activity, setActivity] = useState(emptyActivity);
  const baseURL = (process.env.NODE_ENV === 'development' ? 'http://localhost:3100/' : '/api/');
  // baseURL = '/api/';

  
  React.useEffect(() => {
    console.log(`starting app looking for data from ${baseURL}`);
    axios.get(baseURL).then((ret) => {
      if (ret.status === 200) {
        // console.log('ret.data', ret.data);
        // setActivity(JSON.stringify(ret.data));
        setActivity(ret.data);
      } else {
        const errorActivity = [...emptyActivity];
        errorActivity.method = `error getting data from ${baseURL}`;
        setActivity(errorActivity);
      }
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <ActivityTable activity={activity} />
      </header>
    </div>
  );
}

export default App;
